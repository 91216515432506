import React, { useRef, useEffect,useState } from "react";

import Button from "../elements/Button";
import AnimatedHero from "../parts/AnimatedHero";
// import { ReactComponent as YourSvg } from '../assets/curved-arrow.svg';


export default function Hero1() {
  const [valueI, setValueI] = useState(1);
  const [darkModeStatus, setDarkModeStatus] = useState(false);

  const darkMode = () => {
    var darkModeTemp = !darkModeStatus;
    setDarkModeStatus(darkModeTemp)
    var body = document.body;
    var head = document.getElementById("header");
    var headChild = document.getElementById("header-child");
    var hero1Container = document.getElementById("hero1-container");
    var bulbOn = document.getElementById("bulb-on");
    var hero1Background = document.getElementById("hero1-background");
    body.classList.toggle("body-white");
    head.classList.toggle("header-white");
    headChild.classList.toggle("header-white");
    headChild.classList.toggle("header-dark");
    // hero1Container.classList.toggle("hero1-container-white");
    bulbOn.classList.toggle("bulb-on-white");
    bulbOn.classList.toggle("bulb-on");
    hero1Background.classList.toggle("hero1-background-white");

    var r = document.querySelector(':root');
    
    // const color = ['#bc13fe','#fe1313','#17fe13']
    const color = ['#0cafd4','#fe1313','#bc13fe']
    
    setValueI(valueI+1)
    if(valueI>=2){
      setValueI(0) 
    } 

    var rs = getComputedStyle(r);
    // alert("The value of --blue is: " + rs.getPropertyValue('--colornow'));
    r.style.setProperty('--colornow', color[valueI]);

    if(darkModeTemp == true){
      r.style.setProperty('--neontext', color[valueI]);
    }else{
      r.style.setProperty('--neontext', "#fff");
    }
    console.log(valueI)
    // console.log(`${i} ${rs.getPropertyValue('--colornow')}`)
  }

  return (
    <div id="hero1-container" className="hero1-container" >
        <div style={{position:'relative', width:'100%'}}>
            <div id="hero1-background" className="hero1-background"></div>

            <div className="bulb-div" style={{width:'100%', position:'absolute',zIndex:1}} >
              <img id="bulb-on" className="bulb-on" srcSet={process.env.PUBLIC_URL + '/assets/image/bulb.png'} style={{width:'3%', position:'relative',zIndex:1}} alt="image" onClick={() => darkMode()} />
            </div>
            <h1 className="neonText" style={{fontFamily:"Brush Script MT", position:'absolute', left:'2%', top:'8%', transform: 'rotate(-35deg)', fontSize:'5.7vw'}}><span className="flicker">W</span>elcome</h1>
            {/* <h1 className="neonText-green" style={{fontFamily:"Lucida Handwriting", position:'absolute', left:'40%', top:'70%', transform: 'rotate(8deg)', fontSize:'4.2vw'}}><span className="flicker2">L</span>aravel</h1>
            <h1 className="neonText-red" style={{fontFamily:"Lucida Handwriting", position:'absolute', left:'70%', top:'4%', transform: 'rotate(-4deg)', fontSize:'5.7vw'}}><span className="flicker3">Next</span>JS</h1> */}
            <h1 className="neonText" style={{fontFamily:"", position:'absolute', left:'84%', top:'30%', transform: 'rotate(100deg)', fontSize:'5.7vw', boxShadow:'none', border:'none', zIndex:0}}><span className="flicker4">___</span>_______</h1>
            {/* <h1 className="neonText-red" style={{fontFamily:"", position:'absolute', left:'50%', top:'74%', transform: 'rotate(0deg)', fontSize:'5.7vw', boxShadow:'none', border:'none', zIndex:0}}><span className="flicker5">___</span>______________</h1> */}

            {/* <h1 className="neonLabel-red" style={{fontFamily:"Lucida Handwriting", position:'absolute', left:'18%', top:'34%', transform: 'rotate(-4deg)', fontSize:'1.7vw', boxShadow:'none', border:'none'}}>Javascript</h1>
            <h1 className="neonLabel-green" style={{fontFamily:"Lucida Handwriting", position:'absolute', left:'61%', top:'7%', transform: 'rotate(-30deg)', fontSize:'1.7vw', boxShadow:'none', border:'none'}}>Javascript</h1>
            <h1 className="neonLabel-green" style={{fontFamily:"Lucida Handwriting", position:'absolute', left:'40%', top:'65%', transform: 'rotate(8deg)', fontSize:'1.7vw', boxShadow:'none', border:'none'}}>PHP</h1>
            <h1 className="neonLabel-purple" style={{fontFamily:"Lucida Handwriting", position:'absolute', left:'10%', top:'80%', transform: 'rotate(-12deg)', fontSize:'1.7vw', boxShadow:'none', border:'none'}}>HTML</h1>
            <h1 className="neonLabel-red" style={{fontFamily:"Lucida Handwriting", position:'absolute', left:'70%', top:'78%', transform: 'rotate(-40deg)', fontSize:'1.7vw', boxShadow:'none', border:'none'}}>CSS</h1> */}
            {/* <h1 className="neonLabel-red" style={{fontFamily:"Lucida Handwriting", position:'absolute', left:'57%', top:'30%', transform: 'rotate(-40deg)', fontSize:'1.7vw', boxShadow:'none', border:'none'}}>SQL</h1> */}
            {/* <h1 className="neonLabel-red" style={{fontFamily:"Lucida Handwriting", position:'absolute', left:'40%', top:'20%', transform: 'rotate(20deg)', fontSize:'1.7vw', boxShadow:'none', border:'none'}}>AJAX</h1> */}
            {/* <YourSvg className="svg-neon" style={{ position:'absolute', left:'40%', width:'7%'}}/> */}
            <AnimatedHero />
            <img className="" srcSet={process.env.PUBLIC_URL + "/assets/image/avatar.png"}  style={{position:'relative',marginLeft:'65%', width:'40%', verticalAlign:'bottom', zIndex:1}} alt="image"  />
        </div>
    </div>
  );
}
