import React, { Component } from "react";
import Hero1 from "../parts/Hero1"
import Summary from "../parts/Summary"
import Chart from "../parts/Chart"
import Project from "../parts/Project"
import FormContact from "../parts/FormContact";

export default class DashboardPage extends Component {
  render() {
    return (
      <>
        <Hero1 />
        <br />
        <br />
        <br />
        <Summary />
        <br />
        <br />
        <Chart />
   
        <Project />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <FormContact />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </>
    );
  }
}
