import React, { useRef, useEffect, useState } from "react";

import Button from "../elements/Button";
import AnimatedHero from "./AnimatedHero";


export default function Chart() {
  const [chartPosition, setChartPosition] = useState(0);
  useEffect(() => {
        var chartDiv = document.getElementById("div-chart")
        setChartPosition(chartDiv.offsetTop)
        window.addEventListener('scroll',handleScroll);
    }, [chartPosition]);

    const getNavLinkClass = (path) => {
        return window.location.pathname === path ? "active" : "";
    };
    
    const handleScroll = () => {
        if((window.pageYOffset >= (chartPosition-200)) && (chartPosition !== 0)){
            var r = document.querySelector(':root');
            r.style.setProperty('--b', '22px');
            var chart = document.getElementsByClassName("pie-div");
            for (let i = 0; i < chart.length; i++) {
                chart[i].classList.add("animate");
            }
            setTimeout(function() {
                chart[4].classList.add("pie-flicker1");
                chart[6].classList.add("pie-flicker2"); 
            }, 3000);
            


        }
    }

  return (
    <div id="div-chart" style={{padding:'0 4%'}}>
        <div className="col-md-12 card card-purple" style={{backgroundColor:"#282C34"}}>
            <div className="card-body">
                <div className="col-md-12 row">
                    <div className="col-md-12 col-sm-6 col-xs-6 col-6">
                        <div className="pie-div pie" style={{'--p':'80','--c':'#fe1313',}}> 80% <span style={{fontSize:'15px'}}>SQL</span></div>
                        <div className="pie-div pie" style={{'--p':'90','--c':'#bc13fe',}}> 90% <span style={{fontSize:'15px'}}>Javascript</span></div>
                        <div className="pie-div pie" style={{'--p':'91','--c':'lightblue',}}> 91% <span style={{fontSize:'15px'}}>CSS</span></div>
                        <div className="pie-div pie" style={{'--p':'96','--c':'#17fe13',}}> 96% <span style={{fontSize:'15px'}}>PHP</span></div>
                        <div className="pie-div pie" style={{'--p':'95','--c':'yellow',}}> 95% <span style={{fontSize:'15px'}}>HTML</span></div>
                    </div>
                    <div className="col-md-12 col-sm-6 col-xs-6 col-6">
                        <div className="pie-div pie" style={{'--p':'97','--c':'#fe1313',}}> 97% <span style={{fontSize:'15px'}}>LARAVEL</span></div>   
                        <div className="pie-div pie" style={{'--p':'87','--c':'#bc13fe',}}> 87% <span style={{fontSize:'15px'}}>REACT JS</span></div>   
                        <div className="pie-div pie" style={{'--p':'85','--c':'lightblue',}}> 85% <span style={{fontSize:'15px'}}>NEXT JS</span></div>   
                        <div className="pie-div pie" style={{'--p':'89','--c':'#17fe13',}}> 89% <span style={{fontSize:'15px'}}>JQUERY</span></div>   
                        <div className="pie-div pie" style={{'--p':'91','--c':'yellow',}}> 91% <span style={{fontSize:'15px'}}>BOOTSTRAP</span></div>   
                    </div>
                </div>                
            </div>
        </div>
        {/* <div className="pie" style={{'--p':20}}> 20%</div>
        <div className="pie" style={{'--p':'40','--c':'darkblue','--b':'10px'}}> 40%</div>
        <div className="pie no-round" style={{'--p':'60','--c':'purple','--b':'15px'}}> 60%</div>
        <div className="pie animate no-round" style={{'--p':'80','--c':'red',}}> 80%</div>
        <div className="pie animate" style={{'--p':'90','--c':'purple',}}> 100%</div> */}

        
       
    </div>
  );
}
