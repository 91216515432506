import React, { useState, useRef, useEffect } from "react";

import Button from "../elements/Button";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


export default function FormContact() {
    const nameInputRef = useRef();
    const emailInputRef = useRef();
    const messageInputRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true)

        //axios
        // await axios
        // .get("../sanctum/csrf-cookie")
        // .then(function (response) {
        //     const headers = {
        //         'X-CSRF-TOKEN': response.config.headers['X-XSRF-TOKEN'],
        //         'Content-Type': response.config.headers.Accept, // Adjust the content type based on your needs
        //       };
              

        await axios
            .post("../api/postEmail", {
                name: nameInputRef.current.value,
                email: emailInputRef.current.value,
                message: messageInputRef.current.value
            })
            .then(function (response) {
                const MySwal = withReactContent(Swal)

                MySwal.fire({
                    title: <strong>Thanks!</strong>,
                    html: <i>I Will Contact You Soon!</i>,
                    icon: 'success'
                })
                setIsLoading(false)
                setIsDisabled(true)
            })
            .catch(function (error) {            
                const MySwal = withReactContent(Swal)

                MySwal.fire({
                    title: <strong>Error!</strong>,
                    html: <i>Sorry, Please send me email pmaderama@gmail.com </i>,
                    icon: 'error'
                })
                setIsLoading(false)
            });

        // })
        // .catch(function (error) {
        //     const MySwal = withReactContent(Swal)

        //     MySwal.fire({
        //         title: <strong>Error!</strong>,
        //         html: <i>Sorry, Please send me email pmaderama@gmail.com </i>,
        //         icon: 'error'
        //     })
        //     console.log(error);
        //     setIsLoading(false)
        // });
    };
 
  return (
    <div id="div-contact" className="col-md-12 form-contact" style={{padding: '0 4%', position:'relative'}}>
        <h1 className="neonText contact-me-label"><span className="flicker">C</span>ontact <span className="flicker2">Me</span></h1>

        <div className="card card-purple" style={{backgroundColor:"#282C34", padding: '4% 4%'}}>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <TextField
                    className="form-control"
                    type="text"
                    name="Your Name..."
                    label="Your Name..."
                    placeholder="Your Name..."
                    inputRef={nameInputRef}
                    />
                    <br></br>
                </div>

                <div className="mb-3">
                    <TextField
                    className="form-control"
                    type="text"
                    name="Your Email..."
                    label="Your Email..."
                    placeholder="Your Email..."
                    inputRef={emailInputRef}
                    />
                    <br></br>
                </div>

                <div className="mb-3">
                    <TextField
                    className="form-control"
                    name="Your Message..."
                    label="Your Message..."
                    placeholder="Your Message..."
                    multiline
                    rows={4}
                    inputRef={messageInputRef}
                    />
                    <br></br>
                </div>
                <Button
                    className="btn form-control"
                    isLoading={isLoading}
                    hasShadow
                    isPrimary
                    type="button"
                    isDisabled={isDisabled}
                >
                    Submit
                </Button>
            </form>
            
        </div>
    </div>
  );
}
