import { display } from "@mui/system";
import React, { useState, useEffect } from "react";

import Button from "../elements/Button";

export default function Header(props) {
  const [active, setActive] = useState(0);

  const [navHomeActive, setNavHomeActive] = useState('active');
  const [navAboutActive, setNavAboutActive] = useState('');
  const [navProjectActive, setNavProjectActive] = useState('');
  const [navContactActive, setNavContactActive] = useState('');
  
  useEffect(() => {
    document.title = 'Made Rama P.S';
    getNavLinkClass(window.location.pathname);
    window.addEventListener("scroll", handleScroll);

  }, []);


  const getNavLinkClass = (path) => {
    return window.location.hash === path ? "active" : "";
  };

  const handleScroll = () => {
    // console.log(window.pageYOffset);
   
    const positionProject = document.getElementById("div-project").offsetTop - 250;
    const positionChart = document.getElementById("div-summary").offsetTop - 250;
    const positionContact = document.getElementById("div-contact").offsetTop - 250;
    // console.log('chart: '+positionChart);
    // console.log('contact: '+positionContact);
    var cssVar = document.querySelector(":root");
    if (window.pageYOffset > 200) {
      document.getElementById("headerTemp").style.display = "block";
      document.getElementById("headerTemp").style.position = "relative";
      cssVar.style.setProperty("--headerposition", "fixed");
    } else {
      cssVar.style.setProperty("--headerposition", "relative");
      document.getElementById("headerTemp").style.display = "none";
    }
    if (
      window.pageYOffset >= positionContact &&
      window.location.hash !== "#contact"
    ) {
      window.history.pushState("object or string", "Title", "#contact");
      setNavHomeActive('')
      setNavContactActive('active')
      setNavProjectActive('')
      setNavAboutActive('')
      // console.log('contact')
    }else if (
      window.pageYOffset >= positionProject &&
      window.pageYOffset <= positionContact &&
      window.location.hash !== "#project"
    ) {
      window.history.pushState("object or string", "Title", "#project");
      setNavHomeActive('')
      setNavAboutActive('')
      setNavProjectActive('active')
      setNavContactActive('')
      // console.log('project')
    }else if (
      window.pageYOffset >= positionChart &&
      window.pageYOffset <= positionProject &&
      window.location.hash !== "#about"
    ) {
      window.history.pushState("object or string", "Title", "#about");
      setNavHomeActive('')
      setNavAboutActive('active')
      setNavProjectActive('')
      setNavContactActive('')
      // console.log('about')
    }else if (
      window.pageYOffset >= 0 &&
      window.pageYOffset <= positionChart &&
      window.location.hash !== ""
    ) {
      window.history.pushState("object or string", "Title", "/");
      setNavHomeActive('active')
      setNavAboutActive('')
      setNavProjectActive('')
      setNavContactActive('')
      // console.log('home')
    }
  };

  return (
  
      <header
        id="header"
        style={{ zIndex: 999 }}
      >
        <div id="header-child"  className="spacing-sm header-dark" style={{ zIndex: 999 }}>
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="" style={{ display: "block" }}>
                <div className="">
                  <ul
                    className="navbar-nav ml-auto"
                    style={{ flexDirection: "initial" }}
                  >
                    <li id="nav-home" className={`nav-item ${navHomeActive}`}>
                      <Button
                        className="nav-link neonText"
                        type="link"
                        onClick={() => (document.documentElement.scrollTop = 0)}
                      >
                        Home
                      </Button>
                    </li>
                    <li id="nav-about" className={`nav-item ${navAboutActive}`}>
                      <Button
                        className="nav-link neonText"
                        type="link"
                        onClick={() =>
                          window.scrollTo({ 
                            top: document.getElementById("div-summary").offsetTop - 100, 
                            behavior: 'smooth' 
                          })
                        }
                      >
                        About
                      </Button>
                    </li>
                    <li id="nav-project" className={`nav-item ${navProjectActive}`}>
                      <Button
                        className="nav-link neonText"
                        type="link"
                        onClick={() =>
                          window.scrollTo({ 
                            top: document.getElementById("div-project").offsetTop , 
                            behavior: 'smooth' 
                          })
                        }
                      >
                        Project
                      </Button>
                    </li>
                    <li id="nav-contact" className={`nav-item ${navContactActive}`}>
                      <Button
                        className="nav-link neonText"
                        type="link"
                        onClick={() =>
                          window.scrollTo({ 
                            top: document.getElementById("div-contact").offsetTop - 150, 
                            behavior: 'smooth' 
                          })
                        }
                      >
                        Contact
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>

        <div id="headerTemp"  className="spacing-sm header-dark" style={{display:'none'}}>
          <div className="container">
              <nav className="navbar navbar-expand-lg navbar-light">
                <div className="" style={{ display: "block" }}>
                  <div className="">
                    <ul
                      className="navbar-nav ml-auto"
                      style={{ flexDirection: "initial" }}
                    >
                    </ul>
                  </div>
                </div>
              </nav>
          </div>
        </div>
        
      </header>

      
  );
}
