import React, { useRef, useEffect, useState } from "react";

import Button from "../elements/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { border, fontFamily } from "@mui/system";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';


export default function Project() {
  let project1 = 1
  let project2 = 1
  let project3 = 1
  let project4 = 1
  let project5 = 1
  let project6 = 1
  const [loop, setLoop] = useState(1);
  const [projectPage, setProjectPage] = useState(1);
  const [maxPage, setMaxPage] = useState(2);



  const pageSet = (type) => {
    var tempProjectPage = projectPage;

    if (type == "plus" && (tempProjectPage < maxPage)) {
      tempProjectPage = tempProjectPage + 1;
    } else if (type == "min" && (tempProjectPage > 1)) {
      tempProjectPage = tempProjectPage - 1;
    } else if (type <= maxPage){
      tempProjectPage = type;
    }


    var element = document.getElementsByClassName('project-page active')
    element[0].classList.remove("active");

    element = document.getElementsByClassName('page-' + tempProjectPage)
    element[0].classList.add("active");

    var elementPrevious = document.getElementsByClassName('btn-previous')
    var elementNext = document.getElementsByClassName('btn-next')
    if (tempProjectPage <= 1) {
      elementPrevious[0].classList.add("off");
    } else {
      elementPrevious[0].classList.remove("off");
    }

    if (tempProjectPage >= maxPage) {
      elementNext[0].classList.add("off");
    } else {
      elementNext[0].classList.remove("off");
    }

    element = document.getElementsByClassName('number active')
    element[0].classList.remove("active");

    element = document.getElementsByClassName('number-' + tempProjectPage)
    element[0].classList.add("active");
    setProjectPage(tempProjectPage)
    console.log(tempProjectPage)
    console.log(maxPage)
  }

  const myLoop = () => {         //  create a loop function

    setTimeout(function () {   //  call a 3s setTimeout when the loop is called
      // var project1 = document.getElementById(`projectImg${i}`).style.display = "block";
      document.getElementById(`img${project1}Project1`).classList.toggle("active")
      document.getElementById(`img${project2}Project2`).classList.toggle("active")
      document.getElementById(`img${project3}Project3`).classList.toggle("active")
      document.getElementById(`img${project4}Project4`).classList.toggle("active")
      document.getElementById(`img${project5}Project5`).classList.toggle("active")
      document.getElementById(`img${project6}Project6`).classList.toggle("active")
      document.getElementById(`img${project6}Project7`).classList.toggle("active")
      // console.log('project1 : '+project1);   //  your code here
      // console.log('project2 : '+project2);   //  your code here
      // console.log('project3 : '+project3);   //  your code here
      // console.log('project4 : '+project4);   //  your code here
      // console.log('project5 : '+project5);   //  your code here
      // console.log('project6 : '+project6);   //  your code here
      project1++;
      project2++;
      project3++;
      project4++;
      project5++;
      project6++;
      (project1 > 2) ? project1 = 1 : null; //eslint-disable-line
      (project2 > 3) ? project2 = 1 : null; //eslint-disable-line
      (project3 > 3) ? project3 = 1 : null; //eslint-disable-line
      (project4 > 3) ? project4 = 1 : null; //eslint-disable-line
      (project5 > 3) ? project5 = 1 : null; //eslint-disable-line
      (project6 > 3) ? project6 = 1 : null; //eslint-disable-line
      // console.log('project1 : '+project1);   //  your code here
      // console.log('project2 : '+project2);   //  your code here
      // console.log('project3 : '+project3);   //  your code here
      // console.log('project4 : '+project4);   //  your code here
      // console.log('project5 : '+project5);   //  your code here
      // console.log('project6 : '+project6);   //  your code here

      document.getElementById(`img${project1}Project1`).classList.toggle("active")
      document.getElementById(`img${project2}Project2`).classList.toggle("active")
      document.getElementById(`img${project3}Project3`).classList.toggle("active")
      document.getElementById(`img${project4}Project4`).classList.toggle("active")
      document.getElementById(`img${project5}Project5`).classList.toggle("active")
      document.getElementById(`img${project6}Project6`).classList.toggle("active")
      document.getElementById(`img${project6}Project7`).classList.toggle("active")
      //  if the counter < 10, call the loop function
      myLoop();             //  ..  again which will trigger another 
      //  ..  setTimeout()
    }, 6000)
  }
  if (loop == 1) {
    myLoop();
    setLoop(2);
  }

  const openModalImage = (e) => {
    var modal = document.getElementById("myModal");
    var modalImg = document.getElementById("img01");
    var captionText = document.getElementById("caption");

    modal.style.display = "block";
    modalImg.src = e.target.getAttribute('srcset');
    captionText.innerHTML = e.target.getAttribute('alt');
  }

  const closeModalImage = (e) => {
    var modal = document.getElementById("myModal");
    modal.style.display = "none";
  }



  return (
    <div className="col-md-12 project row" id="div-project" style={{ width: '100%', padding: '0', margin: '0' }}>
      <div className="project-animation">
        <span className="neonText lineFlicker1" style={{ top: '20%', left: '37%', transform: 'rotate(-15deg)' }}>P</span>
        <span className="neonText lineFlicker2" style={{ top: '14%', left: '40%', transform: 'rotate(-10deg)' }}>R</span>
        <span className="neonText lineFlicker3" style={{ top: '10%', left: '43%', transform: 'rotate(-5deg)' }}>O</span>
        <span className="neonText lineFlicker4" style={{ top: '8%', left: '47%', transform: 'rotate(0deg)' }}>J</span>
        <span className="neonText lineFlicker5" style={{ top: '10%', left: '49%', transform: 'rotate(5deg)' }}>E</span>
        <span className="neonText lineFlicker6" style={{ top: '14%', left: '52%', transform: 'rotate(10deg)' }}>C</span>
        <span className="neonText lineFlicker7" style={{ top: '20%', left: '55%', transform: 'rotate(15deg)' }}>T</span>
        {/* <div className="neonLine" style={{top:'20%',left:'43.5%', position:'absolute'}}>
          <span className="neonText lineFlicker1">_</span>
          <span className="neonText lineFlicker2">_</span>
          <span className="neonText lineFlicker3">_</span>
          <span className="neonText lineFlicker4">_</span>
          <span className="neonText lineFlicker5">_</span>
          <span className="neonText lineFlicker6">_</span>
          <span className="neonText lineFlicker7">_</span>
          <span className="neonText lineFlicker8">_</span>
          <span className="neonText lineFlicker9">_</span>
        </div> */}

      </div>

      <div className={`row project-page active page-1`}>
        <div className="col-md-6 col-sm-6 col-xs-6 col-6 div-project">
          <div className="card card-purple card-project" style={{ backgroundColor: "#282C34" }} >
            <img id="img1Project6" className="img-project active" srcSet={process.env.PUBLIC_URL + "/assets/image/project/e-recruitment-tracon.jpg"} alt="Internal Website Company" onClick={((e) => openModalImage(e))} />
            <img id="img2Project6" className="img-project" srcSet={process.env.PUBLIC_URL + "/assets/image/project/e-recruitment-tracon-1.jpg"} alt="Internal Website Company" onClick={((e) => openModalImage(e))} />
            <img id="img3Project6" className="img-project" srcSet={process.env.PUBLIC_URL + "/assets/image/project/e-recruitment-tracon-2.jpg"} alt="Internal Website Company" onClick={((e) => openModalImage(e))} />
            <h3>E-Recruitment (PT Tracon Industri)</h3>
            <p>The company's E-Recruitment website with the best UI/UX (Mobile/PC).</p>
            <div className="label-project">
              <span>LARAVEL 9</span>
              <span>QUEUE</span>
              <span>SERVERSIDE</span>
              <span>JAVASCRIPT</span>
              <span>JQUERY</span>
              <span>AJAX</span>
              <span>CSS</span>
            </div>
        
            <br></br>
            <a className="button-project" href="https://recruitment.tracon.co.id/" target="_blank">
              <svg className="" aria-hidden="true" style={{ width: 20 }} focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
              View
            </a>
          </div>
        </div>





        <div className="col-md-6 col-sm-6 col-xs-6 col-6 div-project">
          <div className="card card-purple card-project" style={{ backgroundColor: "#282C34" }} >
            <img id="img1Project3" className="img-project active" srcSet={process.env.PUBLIC_URL + "/assets/image/project/project-tagging-digital.png"} alt="Internal Website Company" onClick={((e) => openModalImage(e))} />
            <img id="img2Project3" className="img-project" srcSet={process.env.PUBLIC_URL + "/assets/image/project/project-tagging-digital-1.png"} alt="Internal Website Company" onClick={((e) => openModalImage(e))} />
            <img id="img3Project3" className="img-project" srcSet={process.env.PUBLIC_URL + "/assets/image/project/project-tagging-digital-2.png"} alt="Internal Website Company" onClick={((e) => openModalImage(e))} />
            <h3>Tagging Digital (PT POWER INDONESIA)</h3>
            <p>company's internal website to create digital tagging and tracking of company equipment.</p>
            <div className="label-project">
              <span>LARAVEL 8 </span>
              <span>SERVERSIDE</span>
              <span>JAVASCRIPT</span>
              <span>JQUERY</span>
              <span>AJAX</span>
              <span>CSS</span>
            </div>
            <br></br>
            <a className="button-project" href="https://project.maderamaps.com/taggingdigital" target="_blank">
              <svg className="" aria-hidden="true" style={{ width: 20 }} focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
              View
            </a>
          </div>
        </div>


        <div className="col-md-6 col-sm-6 col-xs-6 col-6 div-project">
          <div className="card card-purple card-project" style={{ backgroundColor: "#282C34" }} >
            <img id="img1Project4" className="img-project active" srcSet={process.env.PUBLIC_URL + "/assets/image/project/project-pcmlogistik.png"} alt="Internal Website Company" onClick={((e) => openModalImage(e))} />
            <img id="img2Project4" className="img-project" srcSet={process.env.PUBLIC_URL + "/assets/image/project/project-pcmlogistik-1.png"} alt="Internal Website Company" onClick={((e) => openModalImage(e))} />
            <img id="img3Project4" className="img-project" srcSet={process.env.PUBLIC_URL + "/assets/image/project/project-pcmlogistik-2.png"} alt="Internal Website Company" onClick={((e) => openModalImage(e))} />
            <h3>Logistics Reservation (PT PUTRIINDO LOGISTIK)</h3>
            <p>The company's internal website to make logistics reservations, view reports, graph income reports etc.</p>
            <div className="label-project">
              <span>LARAVEL</span>
              <span>SERVERSIDE</span>
              <span>JAVASCRIPT</span>
              <span>JQUERY</span>
              <span>AJAX</span>
              <span>CSS</span>
            </div>
            <br></br>
            <a className="button-project" href="https://project.maderamaps.com/putriindologistik" target="_blank">
              <svg className="" aria-hidden="true" style={{ width: 20 }} focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
              View
            </a>
          </div>
        </div>

        <div className="col-md-6 col-sm-6 col-xs-6 col-6 div-project">
          <div className="card card-purple card-project" style={{ backgroundColor: "#282C34" }} >
            <img id="img1Project5" className="img-project active" srcSet={process.env.PUBLIC_URL + "/assets/image/project/risk-management.jpg"} alt="Internal Website Company" onClick={((e) => openModalImage(e))} />
            <img id="img2Project5" className="img-project" srcSet={process.env.PUBLIC_URL + "/assets/image/project/risk-management-1.jpg"} alt="Internal Website Company" onClick={((e) => openModalImage(e))} />
            <img id="img3Project5" className="img-project" srcSet={process.env.PUBLIC_URL + "/assets/image/project/risk-management-2.jpg"} alt="Internal Website Company" onClick={((e) => openModalImage(e))} />
            <h3>Risk Management System (PT Tracon Industri)</h3>
            <p>The company's internal website.</p>
            <div className="label-project">
              <span>LARAVEL</span>
              <span>SERVERSIDE</span>
              <span>JAVASCRIPT</span>
              <span>JQUERY</span>
              <span>AJAX</span>
              <span>CSS</span>
            </div>
            <br></br>
            <a className="button-project" href="https://alien.tracon.co.id/" target="_blank">
              <svg className="" aria-hidden="true" style={{ width: 20 }} focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
              View
            </a>
          </div>
        </div>

      </div>

      <div className="row project-page page-2">
        <div className="col-md-6 col-sm-6 col-xs-6 col-6 div-project">
          <div className="card card-purple card-project" style={{ backgroundColor: "#282C34" }} >
            <img id="img1Project5" className="img-project active" srcSet={process.env.PUBLIC_URL + "/assets/image/project/corporate-information.png"} alt="Internal Website Company" onClick={((e) => openModalImage(e))} />
            <h3>Corporate Information (PT Tracon Industri)</h3>
            <p>The company's internal website.</p>
            <div className="label-project">
              <span>LARAVEL</span>
              <span>JAVASCRIPT</span>
              <span>JQUERY</span>
              <span>AJAX</span>
              <span>CSS</span>
            </div>
            <br></br>
            <a className="button-project" href="https://elvis.tracon.co.id/" target="_blank">
              <svg className="" aria-hidden="true" style={{ width: 20 }} focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
              View
            </a>
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-6 col-6 div-project">
          <div className="card card-project" style={{ backgroundColor: "#282C34" }} >
            <img id="img1Project1" className="img-project active" srcSet={process.env.PUBLIC_URL + "/assets/image/project/project1.jpg"} alt="Web Portofolio" onClick={((e) => openModalImage(e))} />
            <img id="img2Project1" className="img-project" srcSet={process.env.PUBLIC_URL + "/assets/image/project/project1-admin1.jpg"} alt="Web Portofolio" onClick={((e) => openModalImage(e))} />
            <h3>Web Portofolio</h3>
            <p>A dynamic, editable web portfolio. This website has admin features that are easy to use for editing. Layout that works on pc and mobile</p>
            <div className="label-project">
              <span>PHP</span>
              <span>JAVASCRIPT</span>
              <span>HTML</span>
              <span>CSS</span>
            </div>
            <br></br>
            <a className="button-project" href="https://project.maderamaps.com/dhame" target="_blank">
              <svg className="" aria-hidden="true" style={{ width: 20 }} focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
              View
            </a>
          </div>
        </div>

        <div className="col-md-6 col-sm-6 col-xs-6 col-6 div-project">
          <div className="card card-purple card-project" style={{ backgroundColor: "#282C34" }} >
            <img id="img1Project2" className="img-project active" srcSet={process.env.PUBLIC_URL + "/assets/image/project/project2.jpg"} alt="Web Portofolio" onClick={((e) => openModalImage(e))} />
            <img id="img2Project2" className="img-project" srcSet={process.env.PUBLIC_URL + "/assets/image/project/project2.2.jpg"} alt="Web Portofolio" onClick={((e) => openModalImage(e))} />
            <img id="img3Project2" className="img-project" srcSet={process.env.PUBLIC_URL + "/assets/image/project/project2.3.jpg"} alt="Web Portofolio" onClick={((e) => openModalImage(e))} />
            <h3>Web Portofolio</h3>
            <p>A dynamic, editable web portfolio. This website has admin features that are easy to use for editing. Layout that works on pc and mobile</p>
            <div className="label-project">
              <span>PHP</span>
              <span>JAVASCRIPT</span>
              <span>HTML</span>
              <span>CSS</span>
            </div>
            <br></br>
            <a className="button-project" href="https://project.maderamaps.com/milka" target="_blank">
              <svg className="" aria-hidden="true" style={{ width: 20 }} focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
              View
            </a>
          </div>
        </div>

        <div className="col-md-6 col-sm-6 col-xs-6 col-6 div-project">
          <div className="card card-purple card-project" style={{ backgroundColor: "#282C34" }} >
            <img id="img1Project7" className="img-project active" srcSet={process.env.PUBLIC_URL + "/assets/image/project/elmo-1.PNG"} alt="Web Portofolio" style={{objectFit: "scale-down"}} onClick={((e) => openModalImage(e))} />
            <img id="img2Project7" className="img-project" srcSet={process.env.PUBLIC_URL + "/assets/image/project/elmo-2.PNG"} alt="Web Portofolio" style={{objectFit: "scale-down"}} onClick={((e) => openModalImage(e))} />
            <img id="img3Project7" className="img-project" srcSet={process.env.PUBLIC_URL + "/assets/image/project/elmo-3.PNG"} alt="Web Portofolio" style={{objectFit: "scale-down"}} onClick={((e) => openModalImage(e))} />
            <h3>Office Supplies (PT Tracon Industri)</h3>
            <p>A dynamic, editable web portfolio. This website has admin features that are easy to use for editing. Layout that works on pc and mobile</p>
            <div className="label-project">
              <span>ANDROID</span>
              <span>IOS</span>
              <span>JAVASCRIPT</span>
              <span>REACT NATIVE</span>
              <span>LARAVEL</span>
            </div>
            <br></br>
            <a className="button-project" href="https://play.google.com/store/apps/details?id=com.tracondev.elmo&hl=en-ID" target="_blank">
              <svg className="" aria-hidden="true" style={{ width: 20 }} focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
              View
            </a>
          </div>
        </div>
      </div>

      {/* <div className="row project-page page-3">
        <div className="col-md-6 div-project">
          <div className="card card-project" style={{ backgroundColor: "#282C34" }} >
            <img id="img1Project1" className="img-project active" srcSet={process.env.PUBLIC_URL + "/assets/image/project/project1.jpg"} alt="Web Portofolio" onClick={((e) => openModalImage(e))} />
            <img id="img2Project1" className="img-project" srcSet={process.env.PUBLIC_URL + "/assets/image/project/project1-admin1.jpg"} alt="Web Portofolio" onClick={((e) => openModalImage(e))} />
            <h3>Web Portofolio</h3>
            <p>A dynamic, editable web portfolio. This website has admin features that are easy to use for editing. Layout that works on pc and mobile</p>
            <div className="label-project">
              <span>PHP</span>
              <span>JAVASCRIPT</span>
              <span>HTML</span>
              <span>CSS</span>
            </div>
            <br></br>
            <a className="button-project" href="https://maderamaps.com/project/project1/index.php" target="_blank">
              <svg className="" aria-hidden="true" style={{ width: 20 }} focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
              View
            </a>
          </div>
        </div>

        <div className="col-md-6 div-project">
          <div className="card card-purple card-project" style={{ backgroundColor: "#282C34" }} >
            <img id="img1Project2" className="img-project active" srcSet={process.env.PUBLIC_URL + "/assets/image/project/project2.jpg"} alt="Web Portofolio" onClick={((e) => openModalImage(e))} />
            <img id="img2Project2" className="img-project" srcSet={process.env.PUBLIC_URL + "/assets/image/project/project2.2.jpg"} alt="Web Portofolio" onClick={((e) => openModalImage(e))} />
            <img id="img3Project2" className="img-project" srcSet={process.env.PUBLIC_URL + "/assets/image/project/project2.3.jpg"} alt="Web Portofolio" onClick={((e) => openModalImage(e))} />
            <h3>Web Portofolio</h3>
            <p>A dynamic, editable web portfolio. This website has admin features that are easy to use for editing. Layout that works on pc and mobile</p>
            <div className="label-project">
              <span>PHP</span>
              <span>JAVASCRIPT</span>
              <span>HTML</span>
              <span>CSS</span>
            </div>
            <br></br>
            <a className="button-project" href="https://maderamaps.com/project/project2" target="_blank">
              <svg className="" aria-hidden="true" style={{ width: 20 }} focusable="false" data-prefix="fas" data-icon="external-link-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg>
              View
            </a>
          </div>
        </div>
        
      </div> */}

      {/* modal */}
      <div id="myModal" className="modal" style={{ zIndex: '9999' }} onClick={((e) => closeModalImage(e))}>
        <span className="close">&times;</span>
        <img className="modal-content" id="img01" />
        <div id="caption"></div>
      </div>
      {/* ///////////////// */}


      <div className="col-md-12 paginate">
        <span className="number" style={{ opacity: '0' }}>1</span>
        <span className="number" style={{ opacity: '0' }}>2</span>
        <span className="number" style={{ opacity: '0' }}>3</span>
        <span className="neonText project-animation btn-previous off" style={{ fontFamily: "Brush Script MT", padding: 2, fontWeight:"bold" }} onClick={() => pageSet("min")}><label className="lineFlicker1" style={{ transform: 'scale(-1, 1)' }}>➤</label> P R E V</span>
        <span className="neonText project-animation btn-next" style={{ fontFamily: "Brush Script MT", padding: 2, fontWeight:"bold" }} onClick={() => pageSet("plus")}>N E X T <label className="lineFlicker3">➤</label></span>
        <span className="number neonText number-1 active" onClick={() => pageSet(1)}>1</span>
        <span className="number neonText number-2" onClick={() => pageSet(2)}>2</span>
        {/* <span className="number neonText number-3" onClick={() => pageSet(3)}>3</span> */}

      </div>
    </div>


  );
}
