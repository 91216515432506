import React, { useRef, useEffect } from "react";

import Button from "../elements/Button";
import '../assets/scss/animation.scss';


export default function AnimatedHero() {
    const ref = useRef(null);
    useEffect(() => {
        const elts = {
            text1: document.getElementById("text1"),
            text2: document.getElementById("text2")
        };
        
        const texts = [
            "Hello",
            "My Name",
            "Made Rama P.S",
            "Programmer",
            "Full Stack Developer",
            ":)",
           
        ];
        const morphTime = 1;
        const cooldownTime = 0.25;
        
        let textIndex = texts.length - 1;
        let time = new Date();
        let morph = 0;
        let cooldown = cooldownTime;
        
        elts.text1.textContent = texts[textIndex % texts.length];
        elts.text2.textContent = texts[(textIndex + 1) % texts.length];
    
    
        const doMorph = () => {
            morph -= cooldown;
            cooldown = 0;
        
            let fraction = morph / morphTime;
        
            if (fraction > 1) {
                cooldown = cooldownTime;
                fraction = 1;
            }
        
            setMorph(fraction);
        }
    
    const setMorph = (fraction) => {
        elts.text2.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
        elts.text2.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;
    
        fraction = 1 - fraction;
        elts.text1.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
        elts.text1.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;
    
        elts.text1.textContent = texts[textIndex % texts.length];
        elts.text2.textContent = texts[(textIndex + 1) % texts.length];
    }
    
    const doCooldown = () => {
        morph = 0;
    
        elts.text2.style.filter = "";
        elts.text2.style.opacity = "100%";
    
        elts.text1.style.filter = "";
        elts.text1.style.opacity = "0%";
    }
    
    const animate = () => {
        requestAnimationFrame(animate);
    
        let newTime = new Date();
        let shouldIncrementIndex = cooldown > 0;
        let dt = (newTime - time) / 1000;
        time = newTime;
    
        cooldown -= dt;
    
        if (cooldown <= 0) {
            if (shouldIncrementIndex) {
                textIndex++;
            }
    
            doMorph();
        } else {
            doCooldown();
        }
    }

    animate();


      }, []);
   

  return (
    <div>
        <div className="animate-home-text" id="container">
            <span ref={ref} id="text1"></span>
            <span ref={ref}id="text2"></span>
        </div>

        <svg id="filters" style={{position:'absolute', display: 'none'}}>
            <defs>
                <filter id="threshold">
                    <feColorMatrix in="SourceGraphic" type="matrix" values="1 0 0 0 0
                                            0 1 0 0 0
                                            0 0 1 0 0
                                            0 0 0 255 -140" />
                </filter>
            </defs>
        </svg>
    </div>
  );
}
