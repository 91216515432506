import React from "react";
import logo from "./logo.svg";
import "./App.css";
import "./assets/scss/style.scss";
import "./assets/scss/darkmode.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./parts/Header";
import Footer from "./parts/Footer";
import DashboardPage from "./pages/DashboardPage";
import axios from 'axios';

const App = () => {
  
  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = 'https://api.maderamaps.com/portofolio/public/api';
  // axios.defaults.baseURL = 'http://localhost/portofolio-backend/public/api';
  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

  // axios
  //   .get("../sanctum/csrf-cookie")
  //   .then(function (response) {
  //     axios.defaults.headers.common['X-XSRF-TOKEN'] = response.config.headers['X-XSRF-TOKEN'];
  //   })
  //   .catch(function (error) {
     
  //     console.log(error);
  //   });

  return (
    <div className="App" id="appDiv">
      <BrowserRouter basename="/">
        <Header />
        <Routes>
          <Route path="/" element={<DashboardPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
