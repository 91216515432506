import React, { useRef, useEffect } from "react";

import Button from "../elements/Button";
import AnimatedHero from "../parts/AnimatedHero";


export default function Summary() {
    

  return (
    <div id="div-summary">
        <div className="col-md-12 row" style={{position:'relative'}}>
            <h1 className="neonText" style={{fontFamily:"", position:'absolute', left:'3%', top:'30%', transform: 'rotate(90deg)', fontSize:'5.7vw', boxShadow:'none', border:'none', zIndex:0}}><span className="flicker4">_</span>___</h1>

            <div className="col-md-6" style={{padding:'2% 4% 2% 5%'}}>
                <div className="card card-purple" style={{backgroundColor:"#282C34"}}>
                    <div className="card-body">
                        <h5 className="card-title">Responsive</h5>
                        <h6 className="card-subtitle mb-2 text-muted">_________</h6>
                        <p className="card-text">My layouts work on any device, big or small, desktop or mobile.</p>
                    </div>
                </div>
            </div>

            <div className="col-md-6" style={{padding:'2% 5% 2% 4%'}}>
                <div className="card card-purple" style={{backgroundColor:"#282C34"}}>
                    <div className="card-body">
                        <h5 className="card-title">Dynamic</h5>
                        <h6 className="card-subtitle mb-2 text-muted">_________</h6>
                        <p className="card-text">I love creating dynamic pages that looks come to life.</p>
                    </div>
                </div>
            </div>

            <div className="col-md-6" style={{padding:'2% 4% 2% 5%'}}>
                <div className="card card-purple flicker" style={{backgroundColor:"#282C34"}}>
                    <div className="card-body">
                        <h5 className="card-title">Editable</h5>
                        <h6 className="card-subtitle mb-2 text-muted">_________</h6>
                        <p className="card-text">I can make editable web. You can easily to edit for example change image or words.</p>
                    </div>
                </div>
            </div>

            <div className="col-md-6" style={{padding:'2% 5% 2% 4%'}}>
                <div className="card card-purple" style={{backgroundColor:"#282C34"}}>
                    <div className="card-body">
                        <h5 className="card-title">Intuitive</h5>
                        <h6 className="card-subtitle mb-2 text-muted">_________</h6>
                        <p className="card-text">Strong preference for easy to use, intuitive UX/UI.</p>
                    </div>
                </div>
            </div>

        </div>
       
    </div>
  );
}
